<template>
  <!-- <img alt="Vue logo" src="/imgs/logo.jpg"> -->
  <!-- <keep-alive> -->
  <Mobile v-if="isMobile" />
  <Pc v-else />
  <!-- </keep-alive> -->

</template>

<script>
import Pc from './components/Pc.vue';
import Mobile from './components/Mobile.vue'

export default {
  name: 'App',
  components: {
    Pc,
    Mobile
  },
  data () {
    return {
    }
  },
  created () {
    //this.type = window.innerWidth > 1000 ? 'pc' : 'mobile'
  },
  mounted () {
    //this.setRemUnit()
    // 窗口大小改变，就重新调用 setRemUnit 方法
    //window.addEventListener('resize', this.setRemUnit)
    //首先我们获得视口高度并将其乘以1%以获得1vh单位的值
    let vh = window.innerHeight * 0.01
    // 然后，我们将——vh自定义属性中的值设置为文档的根
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    //alert(window.innerWidth)
    // 我们监听resize事件 视图大小发生变化就重新计算1vh的值
    window.addEventListener('resize', () => {
      // 我们执行与前面相同的脚本
      let vh = window.innerHeight * 0.01
      console.log(vh);
      document.documentElement.style.setProperty('--vh', `${vh}px`)

    })
  },

  computed: {
    isMobile () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let flag = /QQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|android|Mobile|BlackBerry|IEMobile|Android|avantgo|blackberry|iemobile|ipad|iphone|ipod|ios|phone|pad|pod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
      console.log(flag ? '手机' : 'PC', userAgent)
      return flag;
    },
  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50;
  margin-top: 60px; */
}
</style>
