<template>
  <div class="block scroll-container" @wheel="handleWheel" ref="container">
    <div class="main" ref="scroll" v-show="imageLoaded">
      <el-image class="image__wrapper" @load="imageLoad" src="./imgs/home.png"> </el-image>
      <div v-if="show" id="three-container"></div>
      <div class="oneimg" id="oneimg">
        <p>We are a leading Internet technology service provider that focuses</p>
        <p>on global commercial advertising and marketing products</p>
      </div>
      <div class="two" id="two">
        <p>We are a leading Internet technology service provider that focuses on global commercial</p>
        <p>advertising and marketing products. Our primary markets include Southeast Asia, South Asia,</p>
        <p>Latin America, Europe, and the Middle East. </p>
        <p>We have strong connections with top overseas mobile media platforms such as </p>
        <p> Oppo, Vivo,Xiaomi, and Transsion.</p>
        <p>Our vision is to bridge the gap between brands and users, creating diverse</p>
        <p> application scenarios and integrating marketing strategies with user ecosystems. </p>
        <p>As of 2024, we have successfully served over 1000 Internet and brand companies.</p>
      </div>
      <div class="three">
        <p>dti's intelligent analysis of mobile user data enables targeted users</p>
        <p>to receive marketing advertisementsin the form of images,videos, </p>
        <p>pop-ups, icons, etc., from applications such as app centers,home</p>
        <p>screens,and status bars. It also displays the efficiency of the adplac</p>
        <p>-ements by analyzing data such as exposure,clicks, downloads,</p>
        <p>installations, and usage of the advertising applications.</p>
      </div>
      <div class="four" id="four">
        <p>Relying on the steady growth of brand's factory sales, it covers a<br />
          vast number of users, improves conversion, reduces promotion<br />
          costs,and is unaffected by market fluctuations.</p>
        <p>Brands and advertisers collaborate on marketing strategies by<br /> integrating with mobile applications,
          allowing
          them to target specific<br /> audiences or interest groups effectively. By facilitating installations<br />
          upon
          mobile
          activation, app engagement is significantly improved.</p>
        <p>Utilizing dynamic preloading technology to complete cloud pre-<br />
          installation, resources are automatically loaded during the app<br />
          operation,saving more phone storage space</p>
      </div>
      <div class="five-left" id="five">
        <p>Commercialization support with multi-component<br />
          customization for personalized scenarios. It aids<br />
          brands in precisely targeting users and promotes<br />
          sustained growth in user scale. Full-scenario</p>
      </div>
      <div class="five-right">
        <p>Leverage deep system capabilities to create<br />
          personalized scenarios with multiple components.<br />
          Combine fun interactions with gifts for a dual<br />
          reward, enhancing user engagement.</p>
      </div>
      <div class="six" id="six"></div>
      <div class="six-b">
        <p>Email: VIP@d-ti.com
          <br />
          Address: Room 1618, Howay Technology Building, Nanshan District, Shenzhen City
        </p>
      </div>
      <div class="map">
        <el-image v-intersection class="mapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg bmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg cmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg dmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg emapimg" src="./imgs/map.png"></el-image>
      </div>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="aimg"
        src="./imgs/1.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="bimg"
        src="./imgs/b.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="cimg"
        src="./imgs/c.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="dimg"
        src="./imgs/d.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="eimg"
        src="./imgs/e.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="gimg"
        src="./imgs/g.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="fimg"
        src="./imgs/f.png"></el-image>
      <el-image v-intersection="{ name: 'scale-in-tr', value: 'scale-in-tr' }" class="jimg"
        src="./imgs/j.png"></el-image>
      <div class="a-topback">
        <div class="one-topback" id="a-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active active"></div>
          <div @click="scrollToTarget('b-topback')" class="one-back"></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="b-topback">
        <div class="one-topback" id="b-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back active"></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="bb-topback">
        <div class="one-topback" id="bb-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back active"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="c-topback">
        <div class="one-topback" id="c-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back active"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="d-topback">
        <div class="one-topback" id="d-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back active"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="f-topback">
        <div class="one-topback" id="f-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back active"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="g-topback">
        <div class="one-topback" ref="bbtopback" id="g-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back "></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back active"></div>
        </div>
      </div>
      <div class="foot-topback">
        <div class="one-topback" ref="bbtopback" id="foot-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back "></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back active"></div>
        </div>
      </div>
      <div class="topbaner" @mouseover="handleMouseOver" @mouseout="handleMouseOver">
        <div class="topbanermain" v-show="topbar">
          <a class="one-a" target="_blank" href="https://www.linkedin.com/company/104430356/admin/dashboard/" />
          <a class="two-a" target="_blank" href="https://www.zhipin.com/gongsi/9eeeb7a76129a3731Hd52969E1U~.html" />
        </div>
      </div>

      <!-- <el-image class="bannerimg" src="./imgs/banner.jpg"></el-image> -->
      <!-- <el-image class="oneimg" src="./imgs/one.png"></el-image> -->
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      imageLoaded: false, //页面加载完成
      show: true, //模型
      topbar: false, //右上角点击浮窗
      active: 0,  //当前点击的导航栏
      //scrollLond: false,//滚动条件，间隔快速滚动
      scrollArr: ['a-topback', 'b-topback', 'bb-topback', 'c-topback', 'd-topback', 'f-topback', 'g-topback', 'foot-topback',]//导航栏对应位置
    }
  },
  mounted () {
    console.log('PC页面')
    // setTimeout(() => {
    //   this.init()
    //   window.onresize = function () {
    //     const viewportEl = document.querySelector('body')
    //     var scale = window.devicePixelRatio
    //     // content = 'width=device-width, initial-scale=' + scale + ', minimum-scale=' + scale + ', maximum-scale=' + scale + ', user-scalable=no';
    //     if (viewportEl) {
    //       // viewportEl.setAttribute('content', content);
    //       viewportEl.style.zoom = scale
    //     }
    //   }
    // }, 1000)
    let timeoutId = null;
    window.addEventListener("resize", () => {
      // 窗口大小改变时执行
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      // 设置新的定时器
      timeoutId = setTimeout(() => {
        // 这里执行实际的UI更新操作
        console.log("更新UI: 模型大小改变");
        this.$nextTick(() => {
          const container = document.getElementById('three-container');
          if (container) { container.innerHTML = ""; }
          this.init()
          window.onresize = () => {
            const viewportEl = document.querySelector('body')
            var scale = window.devicePixelRatio
            // content = 'width=device-width, initial-scale=' + scale + ', minimum-scale=' + scale + ', maximum-scale=' + scale + ', user-scalable=no';
            if (viewportEl) {
              // viewportEl.setAttribute('content', content);
              viewportEl.style.zoom = scale
            }
          }
        })
      }, 100); // 延迟500毫秒执行

    });
  },
  methods: {
    imageLoad () { //图片加载完成后加载模型
      this.imageLoaded = true
      this.$nextTick(() => {
        this.init()
        window.onresize = () => {
          const viewportEl = document.querySelector('body')
          var scale = window.devicePixelRatio
          // content = 'width=device-width, initial-scale=' + scale + ', minimum-scale=' + scale + ', maximum-scale=' + scale + ', user-scalable=no';
          if (viewportEl) {
            // viewportEl.setAttribute('content', content);
            viewportEl.style.zoom = scale
          }
        }
      })
    },
    init () { //加载模型
      this.show = true
      const container = document.getElementById('three-container');
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({
        alpha: true,//渲染器透明
        antialias: true,//抗锯齿
        precision: 'highp',//着色器开启高精度
      });
      //开启HiDPI设置
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      renderer.setClearColor(0x000000, 0)
      container.appendChild(renderer.domElement);
      //const ambientLight = new THREE.AmbientLight(0xffffff, 1); // 白光，强度为1
      const loader = new GLTFLoader();
      scene.background = null;
      loader.load('./icon.glb', (gltf) => {
        //scene.add(gltf.scene);
        //scene.background = new THREE.Color("rgba(255, 255, 255, 0)");
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            child.material.emissive = child.material.color;
            child.material.emissiveMap = child.material.map;
          }
        });

        scene.add(gltf.scene);
        //console.log(Math.PI / 10)
        gltf.scene.rotateY(10.96);
        gltf.scene.rotateZ(-0.15);
        gltf.scene.rotateX(0);
        // gltf.scene.scale.set(8.9, 10, 8.9); // 根据需要调整比例
        gltf.scene.scale.set(6.3, 6.6, 6.3); // 根据需要调整比例

      }, undefined, (error) => {
        console.error(error);
      });


      camera.position.z = 5;

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.enableZoom = false; // 禁用缩放
      function animate () {
        requestAnimationFrame(animate);
        scene.rotation.y -= 0.01;
        // 让模型绕Y轴自转
        renderer.render(scene, camera);
        controls.update();
      }

      animate();
    },

    //点击滚动
    scrollToTarget (name, val = 'center') {
      // 获取目标元素
      const target = document.getElementById(name);
      //console.log(target)
      // 如果找到目标元素
      if (target) {
        this.active = this.scrollArr.indexOf(name)
        console.log(target)
        // 使用 scrollIntoView 方法平滑滚动到目标元素
        target.scrollIntoView({ behavior: 'smooth', block: val || 'center' });
      }
    },
    handleMouseOver () { //右上角浮窗
      this.topbar = !this.topbar
    },

    handleWheel (event) { //滚轮节点事件
      event.preventDefault(); // 阻止默认行为, 否则页面会滚动
      // if (this.scrollLond) {
      //   return
      // }
      // this.scrollLond = true
      // 检查滚轮向下移动
      if (event.deltaY < 0) {
        // 向下滚动事件的处理逻辑
        if (this.active > 0) {
          this.active = this.active - 1
        }
        console.log('向上滚动');
      } else {
        if (this.active < 7) {
          this.active = this.active + 1
        }
        //const target = document.getElementById(this.scrollArr[this.active]);
        //target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        console.log('向下滚动');
      }
      const target = document.getElementById(this.scrollArr[this.active]);
      target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // setTimeout(() => {
      //   this.scrollLond = false
      // })
    },

  },
  directives: {
    intersection (el, binding) { //页面可视区域事件
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log('进入可视区域')
            // 当元素进入可视区域，添加动画类
            el.classList.add(binding?.value?.name || 'animated', binding?.value?.value || 'enlarge');
          } else {
            // 当元素离开可视区域，移除动画类
            el.classList.remove(binding?.value?.name || 'animated', binding?.value?.value || 'enlarge');
          }
        });
      });
      observer.observe(el, binding);
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.oooo {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 100px;
  width: 500px;
  height: 600px;
}

.scroll-container {
  /* height: 100vh;
  overflow-y: auto; */
  /* overflow-x: hidden; */
  overflow: hidden;
}

.main {
  position: relative;
  width: 1920px;
  max-width: 100vw;
  /* overflow: hidden; */
  /* min-width: 1920px;
  min-height: 7680px; */
  height: 7680px;
  margin: auto;

  /* overflow-x: auto;
  overflow-y: auto; */
}


.image__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

/* .image__wrapper {
  display: inline-block;
  position: relative;
  width: 1920px;
  height: 7680px;
  background: url("../assets/imgs/home.png") no-repeat;
  background-size: 100% 100%;
} */
#three-container {
  /* width: 858px;
  height: 541px; */
  width: 858px;
  height: 791px;
  position: absolute;
  z-index: 1;
  right: 100px;
  top: 100px
    /* right: 3rem;
  top: 3rem */
}

.oneimg {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 230px;
  top: 750px;
  color: #fff;
  font-size: 26px;
  text-align: left;
}

.oneimg p {
  font-size: 30px;
}

/* p {
  margin: 6px 0;
} */

.two {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 140px;
  top: 1120px;
  color: #1d1d1d;
  font-size: 20px;
  text-align: left;
}

/* .two p {
  font-size: 24px;
} */

.three {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 200px;
  top: 2570px;
  color: #fff;
  font-size: 20px;
  text-align: left;
}

.three p {
  font-size: 24px;
}

.four {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 190px;
  top: 3320px;
  color: #000;
  font-size: 18px;
  text-align: left;
}

.four p {
  font-size: 20px;
  line-height: 32px;
}

.five-left {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 230px;
  top: 4420px;
  color: #000;
  font-size: 18px;
  text-align: left;
}

.five-left p {
  font-size: 20px;
  line-height: 32px;
}

.five-right {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  right: 170px;
  top: 4530px;
  color: #000;
  font-size: 18px;
  text-align: left;
}

.five-right p {
  font-size: 20px;
  line-height: 32px;
}

.six {
  position: absolute;
  width: 100px;
  width: 100px;
  height: 100px;
  right: 210px;
  top: 5170px;
}

.six-b {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  right: 148px;
  bottom: 110px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
}

.one-topback {
  width: 28px;
  height: 219px;
  min-width: 28px;
  min-height: 219px;
  background: url("../assets/imgs/topback1.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  color: #000;
  font-size: 18px;
  text-align: left;
}

.active {
  background: url("../assets/imgs/topactive.png") no-repeat;
  background-size: 100% 100%;
}

.one-active {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 20px;
  cursor: pointer;
  /* background: red; */
}

.one-back {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 46px;
  /* background: red; */
  cursor: pointer;
}

.two-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 74px;
  /* background: red; */
  cursor: pointer;
}

.three-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 100px;
  /* background: red; */
  cursor: pointer;
}

.four-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 130px;
  /* background: red; */
  cursor: pointer;
}

.six-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 156px;
  /* background: red; */
  cursor: pointer;
}

.serr-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 180px;
  /* background: red; */
  cursor: pointer;
}

.a-topback {
  position: absolute;
  right: 100px;
  top: 420px;
}

.b-topback {
  position: absolute;
  right: 100px;
  top: 1338px;
}

.bb-topback {
  position: absolute;
  right: 100px;
  top: 2284px;
}

.c-topback {
  position: absolute;
  right: 100px;
  top: 3250px;
}

.d-topback {
  position: absolute;
  right: 100px;
  top: 4240px;
}

.f-topback {
  position: absolute;
  right: 100px;
  top: 5178px;
}

.g-topback {
  position: absolute;
  right: 100px;
  top: 6130px;
}

.foot-topback {
  position: absolute;
  right: 100px;
  top: 7070px;
  opacity: 0;
}

.map {
  position: absolute;
  left: 20px;
  bottom: 1840px;
}

.mapimg {
  width: 48px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  left: 164px;
  bottom: 238px;
  z-index: 9;
}

.bmapimg {
  left: 628px;
  bottom: 480px;
}

.cmapimg {
  left: 900px;
  bottom: 410px;
}

.dmapimg {
  left: 1072px;
  bottom: 378px;
}

.emapimg {
  left: 1222px;
  bottom: 264px;
}

.mapimg :hover {
  transition: all 0.3s ease;
  transform: scale(0.5, 0.5);
  /* 鼠标悬停时放大图片到1.1倍原大小 */
}

/* 中心放大 */
@keyframes enlarge {
  from {
    transform: scale(0.1);
  }

  to {
    transform: scale(1);
  }
}

.animated {
  animation: enlarge 2s ease-in-out;
}

/* .mapimg {
  animation: enlarge 2s ease-in-out;
   持续时间为 2 秒，无限循环 
} */
/* 翻转 */
@keyframes flip-vertical-bck {

  0% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
    transform: translateZ(-260px) rotateY(-180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }

}

.flip-vertical-bck {
  -webkit-animation: flip-vertical-bck 1s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  animation: flip-vertical-bck 1s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.aimg {
  width: 293px;
  height: 423px;
  position: absolute;
  left: 662px;
  top: 2020px;
  z-index: 9;
}

.bimg {
  width: 302px;
  height: 421px;
  position: absolute;
  left: 924px;
  top: 2290px;
  z-index: 9;
}

.cimg {
  width: 314px;
  height: 421px;
  position: absolute;
  left: 1178px;
  top: 2016px;
  z-index: 9;
}

.dimg {
  width: 160px;
  height: 232px;
  position: absolute;
  left: 244px;
  top: 4066px;
  z-index: 9;
}

.eimg {
  width: 160px;
  height: 232px;
  position: absolute;
  left: 464px;
  top: 4066px;
  z-index: 9;
}

.gimg {
  width: 160px;
  height: 232px;
  position: absolute;
  right: 218px;
  top: 4230px;
  z-index: 9;
}

.fimg {
  width: 160px;
  height: 232px;
  position: absolute;
  right: 440px;
  top: 4230px;
  z-index: 9;
}





.jimg {
  width: 506px;
  height: 248px;
  position: absolute;
  right: 594px;
  top: 4140px;
  z-index: 9;
  opacity: 0;
}

/* 向左下放大 */
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.scale-in-tr {
  -webkit-animation: scale-in-tr 1s;
  animation: scale-in-tr 1s;
  opacity: 1;
}

.bannerimg {
  width: 1920px;
  height: 960px;
  position: absolute;
  left: 0;
  top: 1920px;
  z-index: 99;
}

.topbaner {
  cursor: pointer;
  width: 80px;
  height: 20px;
  position: absolute;
  right: 64px;
  top: 100px;
  z-index: 98;
}

.topbanermain {

  width: 164px;
  height: 140px;
  min-width: 164px;
  min-height: 140px;
  position: absolute;
  right: 14px;
  top: 20px;
  z-index: 99;
  -webkit-animation: scale-in-tr 0.8s;
  animation: scale-in-tr 0.8s;
  background: url("../assets/imgs/topbar.png") no-repeat;
  background-size: 100% 100%;
}

.one-a {
  font-size: 20px;
  color: red;
  z-index: 999;
  display: inline-block;
  margin-top: 22px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* background: red; */
}

.two-a {
  font-size: 20px;
  color: red;
  z-index: 999;
  display: inline-block;
  margin-top: 8px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* background: red; */
}

p {
  margin: 12px 0;
  /* font-size: min(22px, 100%); */

}
</style>