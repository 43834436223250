<template>
  <div class="block scroll-container" @touchstart="handleTouchStart" @touchmove="handleWheel" ref="container">
    <div class="main" ref="scroll" v-show="imageLoaded">
      <!-- <el-image class="image__wrapper" :style="gotopStyle" :src="goimgData">
      </el-image>
      <el-image class="image__wrapper" :style="topStyle" @load="imageLoad" :src="imgData">
      </el-image>
      <el-image class="image__wrapper" :style="uptopStyle" :src="upgoimgData">
      </el-image> -->
      <el-image class="image__wrapper" @load="imageLoad" src="./imgs/Mobile/1.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/2.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/3.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/4.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/5.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/6.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/7.png">
      </el-image>
      <el-image class="image__wrapper" src="./imgs/Mobile/8.png">
      </el-image>
      <div v-if="show" id="three-container"></div>
      <div class="oneimg" id="oneimg">
        <p>We are a leading Internet<br />
          technology service provider<br />
          that focuses on overseas<br />
          commercial advertising and<br />
          marketing products.</p>
      </div>
      <div class="two" id="two">
        <p>
          We are a leading Internet technology service provider that
          focuses on overseas commercial advertising and marketing
          products. Our primarymarkets include Southeast Asia, South
          Asia,Latin America, Europe, and the Middle East.
        </p>
        <p>We have strong connections with top overseas mobile media
          platforms such as Oppo, Vivo, Xiaomi, and Transsion.</p>
        <p>Our vision is to bridge the gap between brands<br /> and users,
          creating diverse application scenarios and integrating
          marketing strategies with user ecosystems. As of 2024,
          we have successfully served over 1000 Internetand brand
          companies.</p>
      </div>
      <div class="three">
        <el-image class="three_img" src="./imgs/Mobile/Effect.png">
        </el-image>
        <p>dti's intelligent analysis of mobile user data enables targeted
          users to receive marketing advertisementsin the form of images,
          videos, pop-ups, icons, etc., from applications such as app
          centers,home screens,and status bars. It also displays the
          efficiency of the adplacements by analyzing data such as
          exposure,clicks, downloads,installations, and usage of the
          advertising applications.</p>
      </div>
      <div class="four" id="four">
        <el-image class="four_img" src="./imgs/Mobile/CloudPreinstallation.png">
        </el-image>
        <p>Relying on the steady growth of brand's factory sales, it covers a
          vast number of users, improves conversion, reduces promotion
          costs,and is unaffected by market fluctuations.</p>
        <p>Brands and advertisers collaborate on marketing efforts.By
          integrating with mobile applications, they can cater to specific
          audiences or interest groups.Achieving installation upon mobile
          activation enhancesapp engagement</p>
        <p>Utilizing dynamic preloading technology to complete cloud pre-
          installation, resources are automatically loaded during the app
          operation,saving more phone storage space</p>
      </div>
      <div class="five-left" id="five">
        <p>Commercialization support with multi
          -component customization for personalized
          scenarios. It aids brands in precisely targeting
          users and promotes sustained growth in user
          scale. Full-scenario</p>
      </div>
      <div class="five-right">
        <p>Leverage deep system capabilities to
          create personalized scenarios with
          multiple components.Combine fun
          interactions with gifts for a dual
          reward, enhancing user engagement.</p>
      </div>
      <!-- <div class="six" id="six"></div>
      <div class="six-b">
        <p>Email: VIP@d-ti.com
          <br />
          Address: Room 1618, Howay Technology Building, Nanshan District, Shenzhen City
        </p>
      </div> -->
      <div class="map">
        <el-image v-intersection class="mapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg bmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg cmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg dmapimg" src="./imgs/map.png"></el-image>
        <el-image v-intersection class="mapimg emapimg" src="./imgs/map.png"></el-image>
      </div>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="aimg"
        src="./imgs/1.png"></el-image>
      <el-image class="aimg_foot" src="./imgs/Mobile/appsplashscreen.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="bimg"
        src="./imgs/b.png"></el-image>
      <el-image class="bimg_foot" src="./imgs/Mobile/apprecommendation.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="cimg"
        src="./imgs/c.png"></el-image>
      <el-image class="cimg_foot" src="./imgs/Mobile/downloadicon.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="dimg"
        src="./imgs/d.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="eimg"
        src="./imgs/e.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="gimg"
        src="./imgs/g.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="fimg"
        src="./imgs/f.png"></el-image>
      <el-image v-intersection="{ name: 'slide-in-fwd-center', value: 'slide-in-fwd-center' }" class="ggimg"
        src="./imgs/Mobile/gg.png"></el-image>
      <el-image class="aaimg slide-in-fwd-br" src="./imgs/Mobile/aa.png"></el-image>
      <el-image v-intersection="{ name: 'slide-in-fwd-tr', value: 'slide-in-fwd-tr' }" class="bbimg"
        src="./imgs/Mobile/bb.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="cc1img"
        src="./imgs/Mobile/cc1.png"></el-image>
      <el-image class="cc1img_foot" src="./imgs/Mobile/MobileSystemActivation.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="cc2img"
        src="./imgs/Mobile/cc2.png"></el-image>
      <el-image class="cc2img_foot" src="./imgs/Mobile/PreinstallationNotification.png"></el-image>
      <el-image v-intersection="{ name: 'flip-vertical-bck', value: 'flip-vertical-bck' }" class="cc3img"
        src="./imgs/Mobile/cc3.png"></el-image>
      <div class="topbaner" @click="handleMouseOver">
        <div class="topbanermain" v-show="topbar">
          <a class="one-a" target="_blank" href="https://www.linkedin.com/company/104430356/admin/dashboard/" />
          <a class="two-a" target="_blank" href="https://www.zhipin.com/gongsi/9eeeb7a76129a3731Hd52969E1U~.html" />
        </div>
      </div>
      <!--<el-image v-intersection="{ name: 'scale-in-tr', value: 'scale-in-tr' }" class="jimg"
        src="./imgs/j.png"></el-image>-->
      <div class="a-topback">
        <div class="one-topback" id="a-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active active"></div>
          <div @click="scrollToTarget('b-topback')" class="one-back"></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="b-topback">
        <div class="one-topback" id="b-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back active"></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="bb-topback">
        <div class="one-topback" id="bb-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back active"></div>
          <div @click="scrollToTarget('c-topback')" class="three-back"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="c-topback">
        <div class="one-topback" id="c-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back active"></div>
          <div @click="scrollToTarget('d-topback')" class="four-back"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="d-topback">
        <div class="one-topback" id="d-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back active"></div>
          <div @click="scrollToTarget('f-topback')" class="six-back"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="f-topback">
        <div class="one-topback" id="f-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back active"></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back"></div>
        </div>
      </div>
      <div class="g-topback">
        <div class="one-topback" ref="bbtopback" id="g-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back "></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back active"></div>
        </div>
      </div>
      <div class="foot-topback">
        <div class="one-topback" ref="bbtopback" id="foot-topback">
          <div @click="scrollToTarget('a-topback')" class="one-active "></div>
          <div @click="scrollToTarget('b-topback')" class="one-back "></div>
          <div @click="scrollToTarget('bb-topback')" class="two-back "></div>
          <div @click="scrollToTarget('c-topback')" class="three-back "></div>
          <div @click="scrollToTarget('d-topback')" class="four-back "></div>
          <div @click="scrollToTarget('f-topback')" class="six-back "></div>
          <div @click="scrollToTarget('g-topback')" class="serr-back active"></div>
        </div>
      </div>
      <!-- <div class="topbaner" @mouseover="handleMouseOver" @mouseout="handleMouseOver">
        <div class="topbanermain" v-show="topbar">
          <a class="one-a" target="_blank" href="https://www.linkedin.com/company/104430356/admin/dashboard/" />
          <a class="two-a" target="_blank" href="https://www.zhipin.com/gongsi/9eeeb7a76129a3731Hd52969E1U~.html" />
        </div>
      </div> -->

      <!-- <el-image class="bannerimg" src="./imgs/banner.jpg"></el-image> -->
      <!-- <el-image class="oneimg" src="./imgs/one.png"></el-image> -->
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import smoothscroll from 'smoothscroll-polyfill'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      imageLoaded: false, //页面加载完成
      show: true, //模型
      topbar: false, //右上角点击浮窗
      active: 0,  //当前点击的导航栏
      startY: 0, // 记录触摸开始的Y坐标
      endY: 0, // 记录触摸移动的Y坐标
      scrollLond: false,//滚动条件，间隔快速滚动
      scrollArr: ['a-topback', 'b-topback', 'bb-topback', 'c-topback', 'd-topback', 'f-topback', 'g-topback', 'foot-topback',]//导航栏对应位置
    }
  },
  mounted () {

    // setTimeout(() => {
    //   this.init()
    //   window.onresize = function () {
    //     const viewportEl = document.querySelector('body')
    //     var scale = window.devicePixelRatio
    //     // content = 'width=device-width, initial-scale=' + scale + ', minimum-scale=' + scale + ', maximum-scale=' + scale + ', user-scalable=no';
    //     if (viewportEl) {
    //       // viewportEl.setAttribute('content', content);
    //       viewportEl.style.zoom = scale
    //     }
    //   }
    // }, 1000)

  },
  methods: {
    imageLoad () { //图片加载完成后加载模型
      this.imageLoaded = true
      this.$nextTick(() => {
        this.init()
        window.onresize = () => {
          //const viewportEl = document.querySelector('body')
          //var scale = window.devicePixelRatio
          //content = 'width=device-width, initial-scale=' + scale + ', minimum-scale=' + scale + ', maximum-scale=' + scale + ', user-scalable=no';
          // if (viewportEl) {
          //   // viewportEl.setAttribute('content', content);
          //   viewportEl.style.zoom = scale
          // }
        }
      })
    },
    init () { //加载模型
      this.show = true
      const container = document.getElementById('three-container');
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({
        alpha: true,//渲染器透明
        antialias: true,//抗锯齿
        precision: 'highp',//着色器开启高精度
      });
      //开启HiDPI设置
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      renderer.setClearColor(0x000000, 0)
      container.appendChild(renderer.domElement);
      //const ambientLight = new THREE.AmbientLight(0xffffff, 1); // 白光，强度为1
      const loader = new GLTFLoader();
      scene.background = null;
      loader.load('./icon.glb', (gltf) => {
        //scene.add(gltf.scene);
        //scene.background = new THREE.Color("rgba(255, 255, 255, 0)");
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            child.material.emissive = child.material.color;
            child.material.emissiveMap = child.material.map;
          }
        });

        scene.add(gltf.scene);
        //console.log(Math.PI / 10)
        gltf.scene.rotateY(10.96);
        gltf.scene.rotateZ(-0.04);
        gltf.scene.rotateX(0);
        // gltf.scene.scale.set(8.9, 10, 8.9); // 根据需要调整比例
        gltf.scene.scale.set(6.3, 6.6, 6.3); // 根据需要调整比例

      }, undefined, (error) => {
        console.error(error);
      });


      camera.position.z = 5;

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = false;
      controls.enableZoom = false; // 禁用缩放
      function animate () {
        requestAnimationFrame(animate);
        scene.rotation.y -= 0.01;
        // 让模型绕Y轴自转
        renderer.render(scene, camera);
        controls.update();
      }

      animate();
    },


    handleMouseOver () { //右上角浮窗
      this.topbar = !this.topbar
    },
    handleTouchStart (e) {//滚轮开始事件
      this.startY = e.touches[0].clientY;
    },
    handleWheel (event) { //滚轮进行事件
      //console.log(event)
      event.preventDefault(); // 阻止默认行为, 否则页面会滚动
      if (this.scrollLond) {
        return
      }
      this.scrollLond = true
      this.endY = event.touches[0].clientY;
      const distance = this.endY - this.startY;
      // 检查滚轮向下移动
      if (distance > 0) {
        // 向下滚动事件的处理逻辑
        if (this.active > 0) {
          this.active = this.active - 1
        }
        //alert('向上滚动');
      } else {
        if (this.active < 7) {
          this.active = this.active + 1
        }
        //const target = document.getElementById(this.scrollArr[this.active]);
        //target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //alert('向下滚动');
      }



      this.$nextTick(() => {
        //   //target.scrollIntoView({ behavior: 'smooth', block: 'start' });


        setTimeout(() => {
          const target = document.getElementById(this.scrollArr[this.active]);
          smoothscroll.polyfill();
          target.scrollIntoView({ block: "start", behavior: "smooth" });

        }, 100)
        //this.$refs.scroll.scroll({ top: -1000, behavior: 'smooth' });
        //target.scroll({ top: 0, left: 0, behavior: 'smooth' });
        //document.querySelector(`#${this.scrollArr[this.active]}`).scrollIntoView({ behavior: 'smooth' });

        //window.scroll({ top: window.innerHeight * this.active, left: 0, behavior: 'smooth' });
        //target.scrollIntoView({ block: "start", behavior: "smooth" });
      })

      setTimeout(() => {
        this.scrollLond = false
      }, 500)
    },


  },
  directives: {
    intersection (el, binding) { //页面可视区域事件
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          //console.log(entry)
          if (entry.isIntersecting) {
            // console.log('进入可视区域')
            // 当元素进入可视区域，添加动画类
            el.classList.add(binding?.value?.name || 'animated', binding?.value?.value || 'enlarge');
          } else {
            // 当元素离开可视区域，移除动画类
            el.classList.remove(binding?.value?.name || 'animated', binding?.value?.value || 'enlarge');
          }
        });
      });
      observer.observe(el, binding);
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body,
html {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
  max-height: calc(100vh - var(--vh-offset, 0px));
  height: calc(100vh - var(--vh-offset, 0px));
}

.scroll-container {
  /* height: 100vh;
  overflow-y: auto; 
  overflow-x: hidden;*/
  /* overflow: hidden; */
  overflow-y: auto;
  font-size: 0;

  height: 9000px;
}

.main {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  /* overflow: hidden; */
  /* min-width: 1920px;
  min-height: 7680px; */
  min-height: calc(100vh - var(--vh-offset, 0px));
  max-height: calc(100vh - var(--vh-offset, 0px));
  height: calc(100vh - var(--vh-offset, 0px));
  margin: auto;
  font-size: 0;
  display: inline-block;
  /* overflow-x: auto; */
  overflow-y: auto;
}


.image__wrapper {
  width: 100vw;
  /* 对于不支持自定义属性的浏览器的回退 */
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  /* height: calc(100vh - var(--vh-offset, 0px)); */
  min-height: calc(100vh - var(--vh-offset, 0px));
  max-height: calc(100vh - var(--vh-offset, 0px));
  height: calc(100vh - var(--vh-offset, 0px));
}

/* .image__wrapper /deep/.el-image__inner {
  min-height: calc(100vh - var(--vh-offset, 0px));
  max-height: calc(100vh - var(--vh-offset, 0px));
  height: calc(100vh - var(--vh-offset, 0px));
} */

/* .image__wrapper {
  display: inline-block;
  position: relative;
  width: 1920px;
  height: 7680px;
  background: url("../assets/imgs/home.png") no-repeat;
  background-size: 100% 100%;
} */
#three-container {
  /* width: 858px;
  height: 541px; */
  width: 1458px;
  height: 1491px;
  position: absolute;
  z-index: 1;
  top: 780px;
  left: 0;
  right: 0;
  margin: auto;
  /* right: 3rem;
  top: 3rem */
}

.oneimg {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  left: 140px;
  top: calc(var(--vh, 1vh) * 100 - 900px);
  color: #fff;
  /* font-size: 26px; */
  text-align: left;
}

.oneimg p {
  font-size: 82px !important;
  -webkit-text-size-adjust: none;
}

/* p {
  margin: 6px 0;
} */

.two {
  text-align: left;
  position: absolute;
  z-index: 2;
  left: 60px;
  top: calc((78vh - var(--vh-offset, 0px)) * 2);
  color: #1d1d1d;
  font-size: 62px;

  /* text-align: left; */
}

.two p {
  line-height: 110px !important;
  font-size: 82px;
  margin: 32px 0;
}

.three {
  position: absolute;
  z-index: 2;
  left: 100px;
  right: 60px;
  top: calc((200vh - var(--vh-offset, 0px) - var(--vh-offset, 0px) + 140px));
  color: #fff;
  font-size: 50px;
  text-align: left;
}

.three_img {
  width: 1000px;
  height: 395px;
  left: 180px
}

.three p {
  line-height: 100px !important;
  font-size: 78px;
  margin: 0 20px;
}

.four {
  color: #000;
  position: absolute;
  z-index: 2;
  left: 60px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 307);

  text-align: left;

}

.four_img {
  width: 1000px;
  height: 395px;
  left: 180px
}

.four p {
  line-height: 110px !important;

  font-size: 78px;
  margin: 32px 0;
}

.five-left {
  color: #000;
  position: absolute;
  z-index: 2;
  left: 60px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + ((100vh - var(--vh-offset, 0px))/100 * 73.5));
  font-size: 76px;
  text-align: left;
  color: #000;
  width: 900px
}

.five-left p {
  font-size: 76px;
  line-height: 84px;
}

.five-right {
  color: #000;
  position: absolute;
  z-index: 2;
  left: 1020px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + ((100vh - var(--vh-offset, 0px))/100 * 73.5));
  font-size: 76px;
  text-align: left;
  color: #000;

}

.five-right p {
  font-size: 76px;
  line-height: 84px;
}

.six {
  position: absolute;
  width: 100px;
  width: 100px;
  height: 100px;
  right: 210px;
  top: 5170px;
}

.six-b {
  width: auto;
  height: 40px;
  position: absolute;
  z-index: 2;
  right: 148px;
  bottom: 110px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
}

.one-topback {
  width: 28px;
  height: 219px;
  min-width: 28px;
  min-height: 219px;
  background: url("../assets/imgs/topback1.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  color: #000;
  font-size: 18px;
  text-align: left;
}

.active {
  background: url("../assets/imgs/topactive.png") no-repeat;
  background-size: 100% 100%;
}

.one-active {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 20px;
  cursor: pointer;
  /* background: red; */
}

.one-back {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 46px;
  /* background: red; */
  cursor: pointer;
}

.two-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 74px;
  /* background: red; */
  cursor: pointer;
}

.three-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 100px;
  /* background: red; */
  cursor: pointer;
}

.four-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 130px;
  /* background: red; */
  cursor: pointer;
}

.six-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 156px;
  /* background: red; */
  cursor: pointer;
}

.serr-back {
  width: 20px;
  height: 20px;
  z-index: 3;
  position: absolute;
  right: 4px;
  top: 180px;
  /* background: red; */
  cursor: pointer;
}

.a-topback {
  position: absolute;
  right: 100px;
  top: 0px;
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.b-topback {
  position: absolute;
  right: 100px;
  top: calc(100vh - var(--vh-offset, 0px));
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.bb-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 2);
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.c-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3);
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.d-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4);
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.f-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 5);
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.g-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 6);
  /* background: red; */
  width: 100px;
  height: 100px;
  opacity: 0;
}

.foot-topback {
  position: absolute;
  right: 100px;
  top: calc((100vh - var(--vh-offset, 0px)) * 7);
  opacity: 0;
  /* background: red; */
  width: 100px;
  height: 100px;
}

.map {
  position: absolute;
  /* left: 20px;
  bottom: 6840px; */
  top: calc((100vh - var(--vh-offset, 0px)) * 5 + ((100vh - var(--vh-offset, 0px))/100 * 79));
  /* top: calc((100vh - var(--vh-offset, 0px)) * 2 + ((100vh - var(--vh-offset, 0px))/100 * 74) + 680px); */
}

.mapimg {
  width: 84px;
  height: 112px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  left: 120px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 8);
  z-index: 9;
}

.bmapimg {
  left: 790px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 2);
}

.cmapimg {
  left: 1140px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 0.6);
}

.dmapimg {
  left: 1490px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 2.8);
}

.emapimg {
  left: 1762px;
  top: calc((100vh - var(--vh-offset, 0px))/100 * 6);
}

.mapimg :hover {
  transition: all 0.3s ease;
  transform: scale(0.5, 0.5);
  /* 鼠标悬停时放大图片到1.1倍原大小 */
}

/* 中心放大 */
@keyframes enlarge {
  from {
    transform: scale(0.1);
  }

  to {
    transform: scale(1);
  }
}

.animated {
  animation: enlarge 2s ease-in-out;
}

/* .mapimg {
  animation: enlarge 2s ease-in-out;
   持续时间为 2 秒，无限循环 
} */
/* 翻转 */
@keyframes flip-vertical-bck {

  0% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
    transform: translateZ(-260px) rotateY(-180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }

}

.flip-vertical-bck {
  -webkit-animation: flip-vertical-bck 1s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  animation: flip-vertical-bck 1s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.aimg {
  width: 500px;
  height: 720px;
  position: absolute;
  left: 200px;
  top: calc((100vh - var(--vh-offset, 0px)) * 2 + ((100vh - var(--vh-offset, 0px))/100 * 46));
  z-index: 9;
}

.aimg_foot {
  width: 350px;
  height: 54px;
  position: absolute;
  left: 270px;
  top: calc((100vh - var(--vh-offset, 0px)) * 2 + ((100vh - var(--vh-offset, 0px))/100 * 46) + 680px);
  z-index: 9;
}

.bimg {
  width: 500px;
  height: 720px;
  position: absolute;
  left: 200px;
  top: calc((100vh - var(--vh-offset, 0px)) * 2 + ((100vh - var(--vh-offset, 0px))/100 * 74));
  z-index: 9;
}

.bimg_foot {
  width: 350px;
  height: 54px;
  position: absolute;
  left: 260px;
  top: calc((100vh - var(--vh-offset, 0px)) * 2 + ((100vh - var(--vh-offset, 0px))/100 * 74) + 680px);
  z-index: 9;
}

.cimg {
  width: 628px;
  height: 842px;
  position: absolute;
  left: 650px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 - 1630px);
  z-index: 9;
}

.cimg_foot {
  width: 350px;
  height: 54px;
  position: absolute;
  left: 780px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 - 820px);
  z-index: 9;
}

.dimg {
  width: 318px;
  height: 464px;
  position: absolute;
  left: 140px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + (100vh - var(--vh-offset, 0px))/100 * 57);
  z-index: 9;
}

.eimg {
  width: 318px;
  height: 464px;
  position: absolute;
  left: 584px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + (100vh - var(--vh-offset, 0px))/100 * 57);
  z-index: 9;
}

.gimg {
  width: 318px;
  height: 464px;
  position: absolute;
  left: 1514px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + (100vh - var(--vh-offset, 0px))/100 * 57);
  z-index: 9;
}

.fimg {
  width: 318px;
  height: 464px;
  position: absolute;
  left: 1070px;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + (100vh - var(--vh-offset, 0px))/100 * 57);
  z-index: 9;
}

.ggimg {
  width: 1066px;
  height: 1328px;
  position: absolute;
  left: 174px;
  right: 0;
  top: calc((100vh - var(--vh-offset, 0px)) * 4 + (100vh - var(--vh-offset, 0px))/100 * 14);
  /* (100vh - var(--vh-offset, 0px))/100 * 2 */
  margin: auto;
  z-index: 9;
}

.aaimg {
  width: 694px;
  height: 1128px;
  position: absolute;
  right: 0px;
  top: calc(100vh - var(--vh-offset, 0px) - 1128px);
  /* top: calc(100vh - 1128px); */
  z-index: 9;

}

.bbimg {
  width: 1100px;
  height: 1431px;
  position: absolute;
  right: 0px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 - 1431px);
  z-index: 9;
}

.cc1img {
  width: 464px;
  height: 680px;
  position: absolute;
  left: 60px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 + ((100vh - var(--vh-offset, 0px))/100 * 71.4));
  margin: auto;
  z-index: 9;
}

.cc1img_foot {
  width: 280px;
  height: 88px;
  position: absolute;
  left: 140px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 + ((100vh - var(--vh-offset, 0px))/100 * 71.4) + 680px);
  z-index: 9;
}

.cc2img {
  width: 464px;
  height: 680px;
  position: absolute;
  left: 470px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 + ((100vh - var(--vh-offset, 0px))/100 * 71.4));
  margin: auto;
  z-index: 9;
}

.cc2img_foot {
  width: 280px;
  height: 88px;
  position: absolute;
  left: 548px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 + ((100vh - var(--vh-offset, 0px))/100 * 71.4) + 680px);
  z-index: 9;
}

.cc3img {
  width: 800px;
  height: 1150px;
  position: absolute;
  right: 10px;
  top: calc((100vh - var(--vh-offset, 0px)) * 3 + ((100vh - var(--vh-offset, 0px))/100 * 63.4));
  margin: auto;
  z-index: 9;
}

.jimg {
  width: 506px;
  height: 248px;
  position: absolute;
  right: 594px;
  top: 4140px;
  z-index: 9;
  opacity: 0;
}

/* 向左下放大 */
@keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

.slide-in-fwd-tr {
  -webkit-animation: slide-in-fwd-tr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-tr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* 向右上放大 */
@-webkit-keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

.slide-in-fwd-br {
  -webkit-animation: slide-in-fwd-br 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-br 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* 向中放大 */
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-2400px);
    transform: translateZ(-2400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.bannerimg {
  width: 1920px;
  height: 960px;
  position: absolute;
  left: 0;
  top: 1920px;
  z-index: 99;
}

.topbaner {
  cursor: pointer;
  width: 110px;
  height: calc((100vh - var(--vh-offset, 0px))/100 * 5);
  position: absolute;
  left: 110px;
  top: 110px;
  z-index: 98;
  /* background: red; */
}

.topbanermain {

  width: 464px;
  height: 380px;
  min-width: 464px;
  min-height: 360px;
  position: absolute;
  left: 14px;
  top: 80px;
  z-index: 99;
  -webkit-animation: scale-in-tr 0.8s;
  animation: scale-in-tr 0.8s;
  background: url("../assets/imgs/topbar.png") no-repeat;
  background-size: 100% 100%;
}

.one-a {
  font-size: 20px;
  color: red;
  z-index: 999;
  display: inline-block;
  margin-top: 22px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* background: red; */
}

.two-a {
  font-size: 20px;
  color: red;
  z-index: 999;
  display: inline-block;
  margin-top: 8px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* background: red; */
}

p {
  line-height: 114px;
  /* font-size: min(22px, 100%); */

}
</style>